import { Routes, Route } from "react-router-dom";
import NoMatch from "pages/404";
import Home from "pages/home";
import Login from "pages/login";
import Layout from "layout";
import AuthRoute from "./auth-route";

export const Router: React.FC = () => {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        <Route path="login" element={<Login />} />
        <Route path="404" element={<NoMatch isShareholder={false} />} />

        <Route element={<AuthRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
      </ Route>

      <Route path="*" element={<NoMatch isShareholder />} />
    </Routes >
  )
}

