import { AxiosResponse } from 'axios';
import { defaultAxiosInstance } from "./instances/axiosInstances"

type UserCredentials = {
  chainAddress: string;
  signature?: string;
}

export const login = (user: UserCredentials): Promise<AxiosResponse<string>> => {
  return defaultAxiosInstance.post(`/shareholders/login`, user)
}