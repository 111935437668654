import axios, { AxiosRequestConfig } from "axios";

export const defaultAxiosInstance = axios.create({
  baseURL: window.__APP_CONFIG__.apiUrl,
  timeout: 10000,
})

export const axiosInstanceWithAuthorization = axios.create({
  baseURL: window.__APP_CONFIG__.apiUrl,
  timeout: 10000,
})

const requestHandler = (request: AxiosRequestConfig) => {
  const token = `Bearer ${localStorage.getItem('token')}`;
  request.headers = request.headers ?? {};
  request.headers.Authorization = token;

  return request;
}

axiosInstanceWithAuthorization.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => Promise.reject(error)
);