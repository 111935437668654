import { CssBaseline } from "@mui/material";
import { DAppProvider } from '@usedapp/core'
import { config } from 'core/connectors'
import AuthProvider from "core/context/auth/provider";
import { Router } from "routing";


const App: React.FC = () => {

  return (<>
    <CssBaseline />
    <DAppProvider config={config}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </DAppProvider>
  </>
  );
}

export default App;
