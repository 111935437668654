import { Grid } from "@mui/material"
import { Outlet } from "react-router-dom"

const Layout: React.FC = () => {


  return (
    <Grid container gridAutoColumns={12} sx={{ height: "100vh", alignItems: 'center' }}>
      <Grid item gridAutoColumns={2} sx={{ m: 'auto' }}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default Layout