import { useState, createContext, useCallback } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";

interface Props {
  children: React.ReactNode;
}
type Token = {
  token: string;
  firstName: string;
  lastName: string;
}
interface TokenEncoded extends JwtPayload {
  firstName: string;
  lastName: string;
}

export interface Context {
  tokenData: Token;
  setJWTToken: (token: string) => void;
  resetJWTToken: () => void;
}
const defaultToken: Token = {
  token: '',
  firstName: '',
  lastName: ''
}

export const AuthContext = createContext<Context | undefined>(undefined);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState<Token>(defaultToken);
  const setJWTToken = useCallback((token) => {
    localStorage.setItem('token', token);
    if (token === "") {
      setToken(token);
    } else {
      const { firstName, lastName } = jwtDecode<TokenEncoded>(token);
      setToken({ token, firstName, lastName });
    }
  }, []);

  const resetJWTToken = useCallback(() => {
    localStorage.removeItem('token');
    setToken(defaultToken)
  }, []);

  const values: Context = {
    tokenData: token, setJWTToken, resetJWTToken
  }

  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider