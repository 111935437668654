import { Button, Typography } from "@mui/material"
import LanguageSelector from "components/language-selector"
import useAuth from "core/context/auth"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const Home: React.FC = () => {
  const { t } = useTranslation('home')
  const { tokenData, resetJWTToken } = useAuth()
  const navigate = useNavigate()
  const handleClick = () => {
    resetJWTToken()
    navigate('/login')
  }

  return (
    <>
      <LanguageSelector />
      <Typography variant="h3" component='h3' sx={{ textTransform: 'capitalize' }}>
        {`${t('home:welcome')}, ${tokenData.firstName} ${tokenData.lastName}`}
      </Typography>
      <Button onClick={handleClick}>{t('home:btn.logout')}</Button>
    </>
  )
}

export default Home