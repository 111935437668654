import { Button, Dialog, DialogActions, DialogTitle, Divider } from "@mui/material"
import { useTranslation } from "react-i18next";


type WalletConnectionType = 'Metamask' | 'WalletConnect'
type Web3ModalProp = {
  open: boolean,
  handleClose: () => void,
  connectToWallet: (value: WalletConnectionType) => void
}


const Web3Modal: React.FC<Web3ModalProp> = ({ open, handleClose, connectToWallet }) => {
  const { t } = useTranslation('web3Connect');

  return (
    <Dialog onClose={handleClose} open={open}>

      <DialogTitle>{t('web3Connect:web3ModalTitle')}</DialogTitle>

      <Divider />

      <Button onClick={() => connectToWallet('Metamask')} >Metamask</Button>
      <Button onClick={() => connectToWallet('WalletConnect')} >WalletConnect</Button>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>{t('web3Connect:btn.close')}</Button>
      </DialogActions>

    </Dialog>
  )
}

export default Web3Modal;