import { Box, Button, Container, Paper } from "@mui/material";
import Web3Connect from "components/web3-connect";
import { useEthers } from '@usedapp/core';
import { login } from "services";
import useAuth from "core/context/auth";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "components/language-selector";
import { useTranslation } from "react-i18next";


const Login: React.FC = () => {
  const { account, deactivate } = useEthers()
  const { setJWTToken } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('login')
  const { library } = useEthers()

  const handleClick = async () => {
    if (account) {
      const signer = library?.getSigner()
      const signature = await signer?.signMessage(account)

      login({ chainAddress: account, signature: signature })
        .then(res => {
          setJWTToken(res.data);
          navigate('/')
        })
        .catch(() => {
          navigate('/404')
        })
    }
  }

  return (
    <Container>

      <LanguageSelector />

      <Box sx={{
        height: 200,
        width: 200,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Web3Connect account={account} handleDeactivate={deactivate} />
          {account &&
            <Button onClick={handleClick} variant='contained'>
              {t('login:login')}
            </Button>
          }
        </Paper>
      </Box>
    </Container>
  );

}

export default Login;