import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import homePl from 'pages/home/translation/pl.json'
import homeEn from 'pages/home/translation/en.json'
import loginPl from 'pages/login/translation/pl.json'
import loginEn from 'pages/login/translation/en.json'
import NoMatchPl from "pages/404/translations/pl.json";
import NoMatchEn from "pages/404/translations/en.json";
import web3ConnectEn from 'components/web3-connect/translations/en.json'
import web3ConnectPl from 'components/web3-connect/translations/pl.json'

const resources = {
  "en-EN": {
    home: homeEn,
    login: loginEn,
    noMatch: NoMatchEn,
    web3Connect: web3ConnectEn
  },
  "pl-PL": {
    home: homePl,
    login: loginPl,
    noMatch: NoMatchPl,
    web3Connect: web3ConnectPl
  }
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    }
  })

export default i18next;