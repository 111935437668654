import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from 'core/context/auth'

const AuthRoute: React.FC = () => {
  const { tokenData } = useAuth();
  const location = useLocation();
  if (tokenData.token === "") {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    tokenData.firstName ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default AuthRoute