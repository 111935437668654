import { AuthContext, Context } from './provider';
import { useContext } from 'react';

export default function useAuth(): Context {
  const authContext = useContext(AuthContext)
  if (authContext === undefined) {
    throw new Error("useAuthContext must be within AuthProvider")
  }

  return authContext
}