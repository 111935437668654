import { Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  isShareholder: boolean;
}

const NoMatch: React.FC<Props> = ({ isShareholder }) => {
  const { t } = useTranslation('noMatch')
  const navigate = useNavigate();

  return (
    <Box sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      {isShareholder &&
        <Box sx={{ maxWidth: "500px" }}>
          <Typography variant="h2" component="h1">{t('noMatch:noMatch')}</Typography>
          <Button variant="contained"
            onClick={() => { navigate('/') }}
          > {t('noMatch:goHome')}</Button>
        </Box>
      }
      {!isShareholder &&
        <Box sx={{ maxWidth: "500px" }}>
          <Typography variant="h2" component="h1">{t('noMatch:noShareholder')}</Typography>
          <Button variant="contained"
            onClick={() => { navigate('/') }}
          > {t('noMatch:goHome')}</Button>
        </Box>
      }
    </Box >
  )
}

export default NoMatch;