import { Button, Grid } from "@mui/material";
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { useEthers } from '@usedapp/core'
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Web3Modal from "./modal";

const rpc_url = process.env["REACT_APP_RPC_URL"] || ""


type Web3ConnectProp = {
  account: string | null | undefined,
  handleDeactivate: () => void
}

const Web3Connect: React.FC<Web3ConnectProp> = ({ account, handleDeactivate }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { activateBrowserWallet, activate } = useEthers()
  const { t } = useTranslation('web3Connect');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConnectToWallet = async (value: string) => {
    switch (value) {
      case "WalletConnect":
        const walletconnect = new WalletConnectConnector({
          rpc: { 1: rpc_url },
          qrcode: true
        })
        activate(walletconnect).catch(err => console.error(err))
        handleClose()
        break;
      case "Metamask":
        activateBrowserWallet()
        handleClose()
        break;
      default:
        break;
    }
  }

  return (
    <Grid container columns={12}>
      {!account &&
        <Grid item>
          <Button variant='contained' onClick={handleClickOpen}>
            {t('web3Connect:btn.web3Login')}
          </Button>
        </Grid>
      }
      {account &&
        <Grid item>
          <Button onClick={handleDeactivate}>{t('web3Connect:btn.web3Logout')}</Button>
        </Grid>
      }

      <Web3Modal
        open={open}
        handleClose={handleClose}
        connectToWallet={handleConnectToWallet}
      />
    </Grid>
  );
}
export default Web3Connect;